import { ChangeEvent as ReactChangeEvent, useCallback } from "react";
import { InputComponent } from "../../Types";
import { useNonInputProps } from "../../InputTypeMapUtils";

export const StringSelector: InputComponent<HTMLSelectElement> = ({
  typeInfoField: { optional, readonly, possibleValues = [] } = {},
  nameOrIndex,
  value,
  onChange,
  ...rest
}) => {
  const nonInputProps = useNonInputProps(rest);
  const onChangeHandler = useCallback(
    ({ target: { value: newValue } }: ReactChangeEvent<HTMLSelectElement>) => {
      onChange(nameOrIndex, newValue === "" ? undefined : `${newValue ?? ""}`);
    },
    [nameOrIndex, onChange],
  );

  return (
    <select
      value={`${value ?? ""}`}
      onChange={onChangeHandler}
      required={!optional}
      disabled={readonly}
      {...nonInputProps}
    >
      {optional ? <option value=""></option> : undefined}
      {possibleValues.map((pV, index) => (
        <option key={`Option:${pV}:${index}`} value={`${pV}`}>
          {pV}
        </option>
      ))}
    </select>
  );
};
